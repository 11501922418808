export default () => ({
    name: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.shippingZone.fields.name',
            rules: ['noEmpty'],
            errors: [],
        },
    },
    zones: {
        props: {
            rules: ['selected'],
            errors: [],
        },
    },
});
