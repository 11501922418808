import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { OrderState, RequestParams } from '@/services/services.types';

class OrderStateService {
    /**
     * Получение статусов заказа
     * @param {RequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов статусов
     */

    async getAll(params: RequestParams = {}): Promise<[CustomError | null, OrderState[]]> {
        const url = `/order-states`;
        const defaultValue = [] as [];
        const errorPath = '[api:orderState:getAll]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Получение конкретного статуса
     * @param {string} id - id статуса
     * @returns {Promise} - объект статуса
     */

    async getOne(id: string): Promise<[CustomError | null, OrderState | null]> {
        const url = `/order-states/${id}`;
        const defaultValue = null;
        const errorPath = '[api:orderState:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание статуса
     * @param {OrderState} orderState - объект статуса
     * @returns {Promise} - объект созданного статуса
     */

    async createOne(orderState: OrderState): Promise<[CustomError | null, OrderState | null]> {
        const url = `/order-states`;
        const defaultValue = null;
        const errorPath = '[api:orderState:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: orderState });
    }

    /**
     * Обновление статуса
     * @param {orderState} orderState - объект статуса
     * @returns {Promise} - объект обновленного статуса
     */

    async updateOne(orderState: OrderState): Promise<[CustomError | null, OrderState | null]> {
        const url = `/order-states/${orderState.id}`;
        const defaultValue = null;
        const errorPath = '[api:orderState:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: orderState });
    }

    /**
     * Удаление статуса
     * @param {string} id - id статуса
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/order-states/${id}`;
        const defaultValue = null;
        const errorPath = '[api:orderState:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new OrderStateService();
